import scrollTo from './scrollTo';

const header = document.querySelector('.Header');

function getScrollTop() {
    return document.body.scrollTop || document.documentElement.scrollTop;
}

export default function scrollToElement(element, options) {
    const box = element.getBoundingClientRect();
    const scrollTop = getScrollTop();
    const headerOffset = header ? header.getBoundingClientRect().height : 0;

    const target = scrollTop + box.top - headerOffset - 20;

    scrollTo(target);
}
